import { useIntl } from 'react-intl';
import {
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';
import { PersonType } from '@models';

export function useColumns(): TableColumn<PersonType>[] {
  const intl = useIntl();
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<PersonType>();

  return [
    {
      datakey: 'tenant',
      sortkey: 'tenant.name',
      filterkey: 'tenant.id',
      name: intl.formatMessage({
        id: 'ESD_PERSON__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ESD_PERSON__COLUMN__NAME',
        defaultMessage: 'Meno',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'surname',
      name: intl.formatMessage({
        id: 'ESD_PERSON__COLUMN__SURNAME',
        defaultMessage: 'Priezvisko',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'kid',
      name: intl.formatMessage({
        id: 'ESD_PERSON__COLUMN__KID',
        defaultMessage: 'KID',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
    },
    {
      datakey: 'personalNumber',
      name: intl.formatMessage({
        id: 'ESD_PERSON__COLUMN__PERSONAL_NUMBER',
        defaultMessage: 'Osobné číslo',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
    },

    ...authoredColumns,
  ];
}
