import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
  listItemsFactory,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButton } from '@components/table-toolbar-import-button/table-toolbar-import-button';
import { Me, Work } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { publicDictionaryListItems } from '@utils/functions';
import { useEvidencePermission } from '@utils/permission-hook';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { useColumns } from './work-columns';
import { Fields } from './work-fields';
import { useValidationSchema } from './work-schema';

export const Works = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Work.WORK_CREATE],
      EDIT: [Permission.Work.WORK_UPDATE],
      REMOVE: [Permission.Work.WORK_DELETE],
      REFRESH: [Permission.Work.WORK_DETAIL],
      SHARE: [Permission.Work.WORK_DETAIL],
      CHECK: [Permission.Work.WORK_CREATE, Permission.Work.WORK_UPDATE],
      SAVE: [Permission.Work.WORK_CREATE, Permission.Work.WORK_UPDATE],
      CLOSE: [Permission.Work.WORK_CREATE, Permission.Work.WORK_UPDATE],
    },
  });

  const defaultWork: Work = {
    id: uuidv4(),
    name: '',
    units: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<Work>({
    version: 6,
    identifier: 'WORK',
    apiProps: {
      url: EvidenceApiUrl.WORK,
      listItems: listItemsFactory<Work>({
        listItemsMethod: (url, params) => {
          handleSwitchSupplier();
          return publicDictionaryListItems(url, params);
        },
      }),
      ...handleApiErrors(regionApiErrors.WORK, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__WORK__TABLE__TITLE',
        defaultMessage: 'Práca',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButton
              importPermissionKey={Permission.Work.WORK_IMPORT}
            />
            <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Work.WORK_DELETE}
            />
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__WORK__DETAIL__TITLE',
          defaultMessage: 'Správa práce',
        }),
        disableBtn: (button) =>
          (['NEW', 'EDIT'].includes(button) && !!user?.supplier) ||
          (button === 'NEW' && !user?.tenant),
        showBtn: permissions,
      },
      initNewItem: () => defaultWork,
    },
  });

  return <Evidence {...evidence} />;
};
