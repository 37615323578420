import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
  listItemsFactory,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButton } from '@components/table-toolbar-import-button/table-toolbar-import-button';
import { Me, Profession } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { publicDictionaryListItems } from '@utils/functions';
import { useEvidencePermission } from '@utils/permission-hook';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { useColumns } from './profession-columns';
import { Fields } from './profession-fields';
import { useValidationSchema } from './profession-schema';

export const Professions = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Profession.PROFESSION_CREATE],
      EDIT: [Permission.Profession.PROFESSION_UPDATE],
      REMOVE: [Permission.Profession.PROFESSION_DELETE],
      REFRESH: [Permission.Profession.PROFESSION_DETAIL],
      SHARE: [Permission.Profession.PROFESSION_DETAIL],
      CHECK: [
        Permission.Profession.PROFESSION_CREATE,
        Permission.Profession.PROFESSION_UPDATE,
      ],
      SAVE: [
        Permission.Profession.PROFESSION_CREATE,
        Permission.Profession.PROFESSION_UPDATE,
      ],
      CLOSE: [
        Permission.Profession.PROFESSION_CREATE,
        Permission.Profession.PROFESSION_UPDATE,
      ],
    },
  });

  const defaultProfession: Profession = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<Profession>({
    version: 3,
    identifier: 'PROFESSION',
    apiProps: {
      url: EvidenceApiUrl.PROFESSION,
      listItems: listItemsFactory<Profession>({
        listItemsMethod: (url, params) => {
          handleSwitchSupplier();
          return publicDictionaryListItems(url, params);
        },
      }),
      ...handleApiErrors(regionApiErrors.PROFESSION, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PROFESSION__TABLE__TITLE',
        defaultMessage: 'Zoznam pracovných pozícii',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButton
              importPermissionKey={Permission.Profession.PROFESSION_IMPORT}
            />
            <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Profession.PROFESSION_DELETE}
            />
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PROFESSION__DETAIL__TITLE',
          defaultMessage: 'Správa pracovných pozícii',
        }),
        disableBtn: (button) =>
          (['NEW', 'EDIT'].includes(button) && !!user?.supplier) ||
          (button === 'NEW' && !user?.tenant),
        showBtn: permissions,
      },
      initNewItem: () => defaultProfession,
    },
  });

  return <Evidence {...evidence} />;
};
