import { Params, useAutocompleteSource } from '@eas/common-web';
import { ProfessionAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useProfessions(params?: Params) {
  return useAutocompleteSource<ProfessionAutocomplete>({
    url: `${EvidenceApiUrl.PROFESSION}/autocomplete`,
    params,
  });
}
