/**
 * Investor permissions
 */
export enum PermissionInvestor {
  /**
   * Prehľad Investorů
   */
  INVESTOR_LIST = 'INVESTOR_LIST',

  /**
   * Prehľad priradených investorů
   */
  INVESTOR_READ_ASSIGNED = 'INVESTOR_READ_ASSIGNED',

  /**
   * Detail investora
   */
  INVESTOR_DETAIL = 'INVESTOR_DETAIL',

  /**
   * Vytvorenie investora
   */
  INVESTOR_CREATE = 'INVESTOR_CREATE',

  /**
   * Editácia investora
   */
  INVESTOR_UPDATE = 'INVESTOR_UPDATE',

  /**
   * Zmazanie investora
   */
  INVESTOR_DELETE = 'INVESTOR_DELETE',

  /**
   * Zneplatnenie investora
   */
  INVESTOR_INVALIDATE = 'INVESTOR_INVALIDATE',

  /**
   * Obnovenie investora
   */
  INVESTOR_REVALIDATE = 'INVESTOR_REVALIDATE',
}
