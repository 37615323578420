export { PermissionPrimaryJournal as PrimaryJournal } from './permissions/perm-primary-journal';
export { PermissionPartialJournal as PartialJournal } from './permissions/perm-partial-journal';
export { PermissionJournalEntry as JournalEntry } from './permissions/perm-journal-entry';
export { PermissionJournalEntryBOZP as JournalEntryBOZP } from './permissions/perm-journal-entry-bozp';
export { PermissionJournalEntryMechanism as JournalEntryMechanism } from './permissions/perm-journal-entry-mechanism';
export { PermissionJournalEntryWorkDone as JournalEntryWorkDone } from './permissions/perm-journal-entry-work-done';
export { PermissionJournalEntryTimesheet as JournalEntryTimesheet } from './permissions/perm-journal-entry-timesheet';
export { PermissionJournalEntryAttachment as JournalEntryAttachment } from './permissions/perm-journal-entry-attachment';
export { PermissionPersonAssignmentPrimaryJournal as PersonAssignmentPrimaryJournal } from './permissions/perm-person-assignment-primary-journal';
export { PermissionPersonAssignmentPartialJournal as PersonAssignmentPartialJournal } from './permissions/perm-person-assignment-partial-journal';
export { PermissionPersonAssignmentSupplier as PersonAssignmentSupplier } from './permissions/perm-person-assignment-supplier';
export { PermissionSupplier as Supplier } from './permissions/perm-supplier';
export { PermissionInvestor as Investor } from './permissions/perm-investor';
export { PermissionPerson as Person } from './permissions/perm-person';
export { PermissionWorkGroup as WorkGroup } from './permissions/perm-work-group';
export { PermissionAbsence as Absence } from './permissions/perm-absence';
export { PermissionProfession as Profession } from './permissions/perm-profession';
export { PermissionTBPP as TBPP } from './permissions/perm-tbpp';
export { PermissionMechanism as Mechanism } from './permissions/perm-mechanism';
export { PermissionMechanismCategory as MechanismCategory } from './permissions/perm-mechanism-category';
export { PermissionWork as Work } from './permissions/perm-work';
export { PermissionWorkCategory as WorkCategory } from './permissions/perm-work-category';
export { PermissionTenant as Tenant } from './permissions/perm-tenant';
export { PermissionUser as User } from './permissions/perm-user';
export { PermissionRole as Role } from './permissions/perm-role';
export { PermissionContext as Context } from './permissions/perm-context';
export { PermissionIntegration as Integration } from './permissions/perm-integration';

// Could be renamed to EAS permissions, as we do not have ADMINISTRATOR or INTERNAL perms
export enum Default {
  /**
   * Eas permissions
   */
  /** Manažment reindexovania */
  REINDEX_ALL = 'REINDEX_ALL',
  /** Manažment sekvencii */
  SEQUENCE_ALL = 'SEQUENCE_ALL',
  /** Manažment plánovania - behy */
  SCHEDULE_JOBS_ALL = 'SCHEDULE_JOBS_ALL',
  /** Manažment plánovania - runy */
  SCHEDULE_RUNS_ALL = 'SCHEDULE_RUNS_ALL',
  /** Auditné logy */
  ALOG_ALL = 'ALOG_ALL',
}
