import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './tenant-columns';
import { Fields } from './tenant-fields';
import { useValidationSchema } from './tenant-schema';

export const Tenants = () => {
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      REFRESH: [Permission.Tenant.TENANT_DETAIL],
      SHARE: [Permission.Tenant.TENANT_DETAIL],
      EDIT: [Permission.Tenant.TENANT_UPDATE],
      CHECK: [Permission.Tenant.TENANT_UPDATE],
      SAVE: [Permission.Tenant.TENANT_UPDATE],
      CLOSE: [Permission.Tenant.TENANT_UPDATE],
      REMOVE: [],
      INVALIDATE: [],
      REVALIDATE: [],
    },
  });

  const evidence = DictionaryEvidence.useDictionaryEvidence({
    version: 1,
    identifier: 'TENANTS',
    apiProps: {
      url: EvidenceApiUrl.TENANTS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__TENANT__TABLE__TITLE',
        defaultMessage: 'Prehľad vlastníkov',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__TENANT__DETAIL__TITLE',
          defaultMessage: 'Správa vlastníkov',
        }),
        showBtn: permissions,
      },
    },
  });

  return <Evidence {...evidence} />;
};
