import React, { useRef, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Tooltip, useFetch, useWebsocketEffect } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { NotificationsMenu } from './notifications-menu';

export const useStyles = makeStyles((theme) => ({
  notificationIcon: {
    color: 'white',
    width: '20px',
    height: '20px',
    padding: 0,
    margin: 0,
  },
  button: {
    height: 33,
    minWidth: 40,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
    },
  },
}));

export function NotificationButton() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [count, , resetCount] = useFetch<number>(
    `${EvidenceApiUrl.NOTIFICATION_RECEIVED}/count`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  const openMenu = () => {
    setOpen(true);
  };

  useWebsocketEffect(() => {
    resetCount();
  }, ['NOTIFICATION_SENT']);

  return (
    <>
      <Tooltip title="Notifikace">
        <Button
          ref={anchorRef}
          variant="contained"
          color="primary"
          onClick={openMenu}
          disableElevation
          className={classes.button}
        >
          <Badge
            badgeContent={count}
            showZero={false}
            color="primary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <NotificationsIcon className={classes.notificationIcon} />
          </Badge>
        </Button>
      </Tooltip>

      <NotificationsMenu
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
        resetCount={resetCount}
      />
    </>
  );
}
