import React, { ReactNode, createContext, useContext, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ApiFilterOperation,
  Filter,
  SnackbarContext,
  SnackbarVariant,
  TableHandle,
  useLocalStorage,
} from '@eas/common-web';
import { JournalEntry, PartialJournal } from '@models';
import { JournalTypeEnum } from '@enums';

export const useStyles = makeStyles(() => ({
  toolbarButton: {},
}));
type LastParentJournal = LastParentJournalEnum | null;

interface TreeStructureContextType {
  treeStructureEnabled: boolean;
  structure: TreeStructure | null;
  toggleStructureInPrimary: (primaryJournalId: string) => void;
  toggleStructureInPartial: (
    partialId: string,
    parentId: string,
    tableRef: React.RefObject<TableHandle<any>>
  ) => void;
  toggleStructureInJournalEntry: (
    journalEntry: JournalEntry,
    tableRef: React.RefObject<TableHandle<any>>
  ) => void;
  updateStructureInPrimary: (primaryJournalId: string) => void;
  updateStructureInPartial: (partialId: string) => void;
  lastParentJournal: LastParentJournal;
  setLastParentJournal: React.Dispatch<React.SetStateAction<LastParentJournal>>;
  journalEntryPrefilters: Filter[];
}

export const TreeStructureContext = createContext<TreeStructureContextType>({
  structure: { primaryJournal: null, partialJournal: null },
  treeStructureEnabled: false,
  toggleStructureInPrimary: () => {},
  toggleStructureInPartial: () => {},
  toggleStructureInJournalEntry: () => {},
  updateStructureInPrimary: () => {},
  updateStructureInPartial: () => {},
  lastParentJournal: null,
  setLastParentJournal: () => {},
  journalEntryPrefilters: [],
});

interface TreeStructure {
  primaryJournal: string | null | undefined;
  partialJournal: string | null | undefined;
}

const TREE_STRUCTURE_ENABLE_MESSAGE = 'Stromová štruktúra bola aktivovaná';
const TREE_STRUCTURE_KILL_MESSAGE = 'Stromová štruktúra bola deaktivovaná';

export enum LastParentJournalEnum {
  PRIMARY = 'PRIMARY',
  PARTIAL = 'PARTIAL',
}

// TODO: add usememos and useEventCallbacks!
export function TreeStructureProvider({ children }: { children: ReactNode }) {
  const [structure, setStructure] = useLocalStorage<TreeStructure | null>(
    'tree_structure',
    null
  );

  // This state is used, so we know from which evidence (PRIMARY or PARTIAL Journal) user navigates to Journal Entry
  // Then we apply filter to Journal Entries /list, so only entries from Primary or Partial Journal are shown.
  const [lastParentJournal, setLastParentJournal] =
    useState<LastParentJournal | null>(null);

  const { showSnackbar } = useContext(SnackbarContext);

  const treeStructureEnabled =
    !!structure?.partialJournal || !!structure?.primaryJournal;

  // It also switches off the tree structure
  const resetStructure = (tableRef?: React.RefObject<TableHandle<any>>) => {
    if (tableRef) tableRef.current?.setPreFilters([]);

    setStructure(null);

    showSnackbar(TREE_STRUCTURE_KILL_MESSAGE, SnackbarVariant.WARNING, true);
  };

  const toggleStructureInPrimary = (prmaryJournalId: string) => {
    if (!treeStructureEnabled) {
      setStructure({ primaryJournal: prmaryJournalId, partialJournal: null });
      showSnackbar(TREE_STRUCTURE_ENABLE_MESSAGE, SnackbarVariant.SUCCESS);
    } else {
      resetStructure();
    }
  };

  const toggleStructureInPartial = (
    partialId: string,
    parentId: string,
    tableRef: React.RefObject<TableHandle<any>>
  ) => {
    if (!treeStructureEnabled) {
      setStructure({
        primaryJournal: parentId,
        partialJournal: partialId,
      });
      tableRef.current?.setPreFilters([
        {
          field: 'parent.id',
          operation: ApiFilterOperation.EQ,
          value: parentId,
        },
      ]);
      showSnackbar(TREE_STRUCTURE_ENABLE_MESSAGE, SnackbarVariant.SUCCESS);
    } else {
      resetStructure(tableRef);
    }
  };

  // Used in primary-journal-fields
  const updateStructureInPrimary = (primaryJournalId: string) => {
    if (treeStructureEnabled)
      setStructure({ primaryJournal: primaryJournalId, partialJournal: null });
  };

  // It is used in partial-journal-fields to update structure on every partial journal item change => so if the user
  // then navigates to Journal Entries - proper entries will be shown
  const updateStructureInPartial = (partialId: string) => {
    if (treeStructureEnabled) {
      setStructure((prev) => ({
        ...prev,
        partialJournal: partialId,
      }));
    }
  };

  const toggleStructureInJournalEntry = (
    journalEntry: JournalEntry,
    tableRef: React.RefObject<TableHandle<any>>
  ) => {
    // It is either Partial Journal or Primary Journal. Partial will have parent attribute.
    const journal = journalEntry?.journal;

    if (treeStructureEnabled) {
      resetStructure(tableRef);
    } else {
      if (journal?.journalType === JournalTypeEnum.PRIMARY) {
        setStructure({
          primaryJournal: journal?.id,
          partialJournal: null,
        });
      } else if (journal?.journalType === JournalTypeEnum.PARTIAL) {
        const partialJournal = journal as PartialJournal;
        setStructure({
          primaryJournal: partialJournal?.parent?.id,
          partialJournal: journal?.id,
        });
      }
      tableRef.current?.setPreFilters([
        {
          field: 'journal.id',
          operation: ApiFilterOperation.EQ,
          value: journal?.id,
        },
      ]);
      showSnackbar(TREE_STRUCTURE_ENABLE_MESSAGE, SnackbarVariant.SUCCESS);
    }
  };

  const journalEntryPrefilters = treeStructureEnabled
    ? [
        {
          field: 'journal.id',
          operation: ApiFilterOperation.EQ,
          value:
            lastParentJournal === LastParentJournalEnum.PRIMARY
              ? structure.primaryJournal
              : structure.partialJournal ?? structure.primaryJournal,
        },
      ]
    : [];

  return (
    <TreeStructureContext.Provider
      value={{
        structure,
        treeStructureEnabled,
        toggleStructureInPrimary,
        toggleStructureInPartial,
        updateStructureInPrimary,
        updateStructureInPartial,
        lastParentJournal,
        setLastParentJournal,
        journalEntryPrefilters,
        toggleStructureInJournalEntry,
      }}
    >
      {children}
    </TreeStructureContext.Provider>
  );
}
