import { Params, useAutocompleteSource } from '@eas/common-web';
import { InvestorAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useInvestors(params?: Params) {
  return useAutocompleteSource<InvestorAutocomplete>({
    url: EvidenceApiUrl.INVESTOR + '/autocomplete',
    params,
  });
}
