import { EvidenceApiUrl } from '@enums';

export function loginDev() {
  return fetch('/api/esd/login', {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({
      username: 'admin',
      password: 'pass',
    }),
  });
}

export function initVsdEasSession(token: string) {
  return fetch(`${EvidenceApiUrl.LOGIN}/oauth/vsd/web`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Adjust content type if necessary
    },
  });
}

export function initZsdEasSession(idToken: string) {
  return fetch(`${EvidenceApiUrl.LOGIN}/oauth/zsd`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json', // Adjust content type if necessary
    },
  });
}

export function refreshVsdSession(token: string) {
  return initVsdEasSession(token);
}
export function refreshZsdSession(idToken: string) {
  return initZsdEasSession(idToken);
}
