/**
 * Browser urls
 */
export enum EvidenceBrowserUrl {
  HOME = '/',
  LOGIN = '/prihlasenie',

  PRIMARY_JOURNAL = '/hlavne-denniky',
  PARTIAL_JOURNAL = '/dielce-denniky',
  JOURNAL_ENTRY = '/denne-zaznamy',
  SUPPLIER = '/dodavatelia',
  PERSON = '/osoby',
  USERS = '/uzivatelia',
  TENANTS = '/vlastnici',
  INVESTOR = '/investori',
  ROLES = '/role',

  MECHANISM_CATEGORY = '/mechanizmy-kategorie',
  MECHANISMS = '/mechanaizmy',
  WORK = '/prace',
  WORK_CATEGORY = '/kategorie-prac',
  TBPP = '/prace-tbpp',
  PROFESSION = '/profesie',
  WORK_GROUP = '/pracovne-skupiny',
  ABSENCE = '/nepritomnosti',

  ADMIN_SOAP_MESSAGES = '/soap-zpravy',
  ALOG = '/alog',
  // APP_SETTINGS = '/nastavenia-aplikacie',
  // INTERNAL_SETTINGS = '/nastavenia',
  EXPORT_REQUESTS = '/export-fronty',
  EXPORT_TEMPLATES = '/export-sablony',
  SEQUENCES = '/sekvencie',
  SCHEDULE_JOBS = '/casove-ulohy',
  SCHEDULE_RUNS = '/casove-behy',
}

export enum EvidenceApiUrl {
  ABSENCE = '/api/esd/absence',
  ATTACHMENT = '/api/esd/attachment',
  CONTEXT = '/api/esd/context',
  CONTEXT_SWITCH = '/api/esd/context/switch',
  INTERNAL_SETTINGS = '/api/esd/settings',
  INVESTOR = '/api/esd/investor',
  JOURNAL_ENTRY = '/api/esd/journal/entry',
  LOGIN = '/api/esd/login',
  // Logs out only the eas session
  LOGOUT = '/api/esd/logout',
  MECHANISM_CATEGORY = '/api/esd/mechanism/category',
  MECHANISMS = '/api/esd/mechanism',
  NOTIFICATION_RECEIVED = '/api/esd/notification/received',
  PARTIAL_JOURNAL = '/api/esd/journal/partial',
  PERMISSION = '/api/esd/public/permission',
  PERSON = '/api/esd/person',
  PRIMARY_JOURNAL = '/api/esd/journal/primary',
  PROFESSION = '/api/esd/profession',
  ROLES = '/api/esd/role',
  SUPPLIER = '/api/esd/supplier',
  SYSTEM_VERSION = '/api/esd/system/info',
  TBPP = '/api/esd/work/tbpp',
  TENANTS = '/api/esd/tenant',
  USERS = '/api/esd/user',
  WORK = '/api/esd/work',
  WORK_CATEGORY = '/api/esd/work/category',
  WORK_GROUP = '/api/esd/workgroup',
}
