import { useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useWebsocketEffect } from '@eas/common-web';
import { refreshVsdSession, refreshZsdSession } from '@modules/login/login-api';
import { AuthConfigContext } from '@components/auth/auth-config-provider';

export const useRefreshEasSession = () => {
  const { idTokenData, token, idToken } = useContext(AuthContext);
  const { authConfig } = useContext(AuthConfigContext);

  const isTokenVsd = idTokenData?.aud === authConfig.vsd?.registration.clientId;
  const isTokenZsd = idTokenData?.aud === authConfig.zsd?.registration.clientId;

  useWebsocketEffect(() => {
    const refreshEasSession = async () => {
      if (token && isTokenVsd) await refreshVsdSession(token);

      if (idToken && isTokenZsd) await refreshZsdSession(idToken);
    };

    refreshEasSession();
  }, ['SESSION_EXPIRED']);
};
