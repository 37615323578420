import { abortableFetch, useAutocompleteSource } from '@eas/common-web';
import { Role, User } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useRoles() {
  return useAutocompleteSource<Role>({
    url: `${EvidenceApiUrl.ROLES}/autocomplete`,
  });
}
export function useUsers() {
  return useAutocompleteSource<User>({
    url: `${EvidenceApiUrl.USERS}/autocomplete`,
  });
}

export function switchSupplier(supplierId?: string) {
  return abortableFetch(`${EvidenceApiUrl.CONTEXT_SWITCH}/supplier`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: supplierId,
  });
}

export function switchTenant(tenantId?: string) {
  return abortableFetch(`${EvidenceApiUrl.CONTEXT_SWITCH}/tenant`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: tenantId,
  });
}
