import React, { useContext } from 'react';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import {
  EvidenceContext,
  TableActionButton,
  TableSelectedContext,
  UserContext,
} from '@eas/common-web';
import { deleteMultipleGridItems } from './multiple-delete-api';

export function MultipleDeleteTableToolbarButton({
  deletePermissionKey = '',
}: {
  deletePermissionKey: string;
}) {
  const { hasPermission } = useContext(UserContext);
  const { apiUrl } = useContext(EvidenceContext);
  const { selected } = useContext(TableSelectedContext);

  return (
    <>
      {hasPermission(deletePermissionKey) && (
        <TableActionButton
          promptKey={`MULTIPLE_DELETE_${deletePermissionKey}`}
          apiCall={() => deleteMultipleGridItems(apiUrl, selected)}
          successMessage="Denníky boli úspešne zmazané."
          buttonLabel={<DeleteSweepIcon />}
          dialogTitle="Upozornenie"
          dialogText={`Naozaj chcete zmazať ${selected.length} ${
            selected.length === 1 ? 'položku' : 'položky'
          }? Táto akcia je nevratná.`}
          confirmLabel="Zmazať"
          buttonTooltip="Zmazať"
          buttonDisabled={!selected.length}
        />
      )}
    </>
  );
}
