import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { JournalEntry, Me } from '@models';
import {
  EvidenceApiUrl,
  JournalEntryState,
  Permission,
  PrimaryJournalState,
} from '@enums';

export function useSignJournalEntryByTimestamp() {
  const {
    source: { data },
  } = useContext<DetailHandle<JournalEntry>>(DetailContext);

  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (entryId: string) => {
    return abortableFetch(`${EvidenceApiUrl.JOURNAL_ENTRY}/${entryId}/sign`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        type: 'TIMESTAMP',
        signedBy: { id: user?.person?.id },
        timestamp: new Date(),
      }),
    });
  };

  const showButton =
    data?.journal?.state !== PrimaryJournalState.CANCELLED &&
    data?.state === JournalEntryState.CLOSED &&
    hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_SIGN);

  return { callApi, showButton };
}
