import { abortableFetch } from '@eas/common-web';

export const deleteMultipleGridItems = (apiUrl: string, selected: string[]) => {
  // TODO: when BE is done change the endpoint path
  return abortableFetch(`${apiUrl}/multiple/delete`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(selected),
  });
};
